import React from "react";
import {useTranslation} from "react-i18next";

const AboutCard = ({ item, local }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="about-box bg-transparent border-[#E3E3E3] dark:border-[#212425] border"
    >
      <img className="w-10 h-10 object-contain block" src={ local === "dark" ? item.icon_light : item.icon_dark} alt={item.title} />

      <div className="space-y-2 break-all">
        <h3 className="dark:text-white text-xl font-semibold custom-break-words">{item?.title}</h3>
        <p className="leading-8 text-gray-lite dark:text-[#A6A6A6] custom-break-words">
          {item['des_' + i18n.language]}
        </p>
      </div>
    </div>
  );
};
export default AboutCard;

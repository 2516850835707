import React from "react";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import CommonContact from "./CommonContact";
import {useTranslation} from "react-i18next";

const ContactTwo = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <PageTitle title={t("Contact")}></PageTitle>

            <div className="bg-white lg:rounded-2xl dark:bg-[#111111]">
                <h2 className="after-effect after:top-[76px] mb-12 md:mb-[30px] pl-4 md:pl-[60px] pt-12">
                    {t("Contact")}
                </h2>

                <CommonContact condition={true} />
                <Footer bg={"#F8FBFB"} />
            </div>
        </>
    );
};

export default ContactTwo;

import React from "react";
import cvFileSK from "../assets/files/CV-SZABO-SK.pdf";
import cvFileEN from "../assets/files/CV-SZABO-EN.pdf";
import {
  FaCalendarAlt,
  FaEnvelopeOpenText,
  FaFacebookF, FaGithub, FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
} from "react-icons/fa";
import avatar from "../assets/images/about/carma.jpg";
import avatarAvif from "../assets/images/about/carma.avif";
import downloadIcon from "../assets/images/download.png";
import {useTranslation} from "react-i18next";

const HomeCard = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 ">
        <picture>
          <source srcSet={avatarAvif} type="image/avif" />
          <img
              src={avatar}
              alt="avatar"
              className="object-cover w-[240px] absolute left-[50%] transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto  rounded-[20px] -mt-[140px]"
          />
        </picture>
        {/* Social card */}
        <div className="pt-[100px] pb-8">
          <h1 className="mt-6 mb-1 text-5xl font-semibold  dark:text-white">
            Andrej Szabo
          </h1>
          <span className="mb-4  inline-block bg-[#F3F6F6] dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-white">
            Web developer
          </span>

          {/* Social Links */}

          <div className="flex justify-center space-x-3">
            {/* facebook link add here */}
            <a
              href="https://www.facebook.com/andrej.carma.szabo/"
              target="_blank"
              aria-label="Facebook"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1773EA]">
                <FaFacebookF />
              </span>
            </a>
            {/* instagram link add here */}
            <a
              href="https://www.instagram.com/c4rm4gg3d0n/"
              target="_blank"
              aria-label="Instagram"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#e14a84] ">
                <FaInstagram />
              </span>
            </a>
            {/* linkedin link add here */}
            <a
              href="https://www.linkedin.com/in/andrej-szabo-950410136/"
              target="_blank"
              aria-label="Linkedin"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#0072b1]">
                <FaLinkedinIn />
              </span>
            </a>
            <a
                href="https://github.com/Carmagedon"
                target="_blank"
                aria-label="GitHub"
                rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#0072b1]">
                <FaGithub />
              </span>
            </a>
          </div>

          {/* personal information */}
          <div className="p-7 rounded-2xl mt-7  bg-[#F3F6F6] dark:bg-[#1D1D1D]">
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] pb-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#E93B81] shadow-md">
                <FaMobileAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  {t("phone")}
                </p>
                <p className="dark:text-white break-all">
                  {" "}
                  <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="tel:+421908869979"
                  >
                    +421 908 869 979
                  </a>
                </p>
              </div>
            </div>

            <div className="flex  border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#6AB5B9] shadow-md">
                <FaEnvelopeOpenText />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  {t("email")}
                </p>
                <p className="dark:text-white break-all">
                  {" "}
                  <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="mailto:andrej.szabo@gmail.com"
                  >
                    andrej.szabo@gmail.com
                  </a>
                </p>
              </div>
            </div>

            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#FD7590] shadow-md">
                <FaMapMarkerAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  {t("location")}
                </p>
                <p className="dark:text-white break-all">Bratislava Slovakia</p>
              </div>
            </div>

            <div className="flex pt-2.5">
              <span className="flex-shrink-0 socialbtn bg-white dark:bg-black text-[#C17CEB] shadow-md">
                <FaCalendarAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  {t("birthday")}
                </p>
                <p className="dark:text-white break-all">November 20, 1991</p>
              </div>
            </div>
          </div>

          <a
            href={i18n.language === 'sk' ? cvFileSK : cvFileEN}
            download
            className="inline-flex items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l px-8 py-3 text-lg text-white rounded-[35px] mt-6"
          >
            <img src={downloadIcon} alt="download icon" className="mr-2" />
            {t("downloadCV")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;

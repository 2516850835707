import React from 'react';
import CommonPortfolioItem from "./CommonPortfolioItem";


const PortfolioItem = () => {
    return (
        <div>
            <CommonPortfolioItem />
        </div>
    );
};

export default PortfolioItem;

import React from "react";
import { Helmet } from "react-helmet-async";
import {useTranslation} from "react-i18next";

const PageTitle = ({ title }) => {
    const { t, i18n } = useTranslation();

    return (
        <Helmet>
            <title>{ title } | Andrej Szabo – Full-stack Web developer</title>
            <meta name="description" content={t("MetaDescription")}/>
        </Helmet>
    );
};

export default PageTitle;

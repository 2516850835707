import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "./PageTitle";
import {useTranslation} from "react-i18next";

const NotFound = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <PageTitle title="404"></PageTitle>
      <div className="bg-gradient h-screen w-full flex flex-col justify-center items-center  bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed">
        <h1 className="text-7xl leading-none text-white font-extrabold md:text-8xl">
          404!
        </h1>
        <p className=" text-medium text-white text-sm mb-7 mt-6 px-4 text-center">
          {t("pageNotFound")}
        </p>

        <Link
          to="/"
          className="flex items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[5px]"
        >
          {t("backToHome")}
        </Link>
      </div>
    </>
  );
};

export default NotFound;

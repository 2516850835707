import React from "react";
import UseData from "../Hooks/UseData";

const Footer = ({ bg, condition }) => {
  const { local } = UseData();
  return (
    <footer className="overflow-hidden rounded-b-2xl">
      <p className="text-center py-6 text-gray-lite dark:text-color-910">
        © ANDREJ SZABO { new Date().getFullYear() }
      </p>
    </footer>
  );
};

export default Footer;

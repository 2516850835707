import React from "react";
import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";
import UseData from "../../Hooks/UseData";
import {useTranslation} from "react-i18next";
import cvFileSK from "../../assets/files/CV-SZABO-SK.pdf";
import cvFileEN from "../../assets/files/CV-SZABO-EN.pdf";

const ResumeCardTwo = () => {
  const { educationArray, experiencesArray } = UseData();
  const { local } = UseData();
  const { t, i18n } = useTranslation();

  return (
      <>
        <div>

          <div className="flex items-center space-x-2 mb-4">
            <div className="text-6xl text-[#F95054]">
              <MdOutlineSchool/>
            </div>
            <h4 className="text-5xl dark:text-white font-medium">
              {t("Education")}
            </h4>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6">
            {educationArray.map((item) => (
                <div
                    className="py-4 pl-5 pr-3 space-y-2 rounded-lg border-[#E3E3E3] dark:border-[#212425] border bg-transparent"
                    key={item.id}
                >
                  <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
                    {item['date_' + i18n.language]}
                  </span>

                    <h3>
                        {item['university_' + i18n.language] &&
                            <p className="text-tiny dark:text-[#b7b7b7]">{item['university_' + i18n.language]}</p>
                        }
                        <span className="text-base dark:text-white font-bold">{item['title_' + i18n.language]}</span>
                    </h3>

                    {item['department_' + i18n.language] &&
                        <p className="dark:text-white">{item['department_' + i18n.language]}</p>
                    }
                    <p className="text-xtiny dark:text-[#b7b7b7]">{item['place_' + i18n.language]}</p>
                </div>
            ))}
          </div>

        </div>

          <div>

              <div className="flex items-center space-x-2 mb-4">
                  <div className="text-6xl text-[#F95054]">
                      <MdOutlineBusinessCenter/>
                  </div>
                  <h4 className="text-5xl dark:text-white font-medium">{t("WorkExperience")}</h4>
              </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6">
            {experiencesArray.map((item) => (
                <div
                    className="py-4 pl-5 pr-3 space-y-2 rounded-lg border-[#E3E3E3] dark:border-[#212425] border bg-transparent"
                    key={item.id}
                >
                <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
                  {item['date_' + i18n.language]}
                </span>

                  <h3>
                  <span className="text-xl dark:text-white font-bold">
                    {item['company_' + i18n.language]}
                  </span>
                  </h3>
                  {item['title_' + i18n.language] &&
                      <p className="dark:text-white">{item['title_' + i18n.language]}</p>
                  }
                  <p className="text-xtiny dark:text-[#b7b7b7]">{item['place_' + i18n.language]}</p>
                </div>
            ))}

              <a
                  href={i18n.language === 'sk' ? cvFileSK : cvFileEN}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center py-4 pl-5 pr-3 space-y-2 rounded-lg  dark:border-[#212425] dark:border-2 bg-gradient-to-r duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef]"
              >

                  <h3 className="flex justify-center items-center flex-col">
                      <svg className="h-12 w-auto fill-white mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path d="M4,4v16c0,1.105,0.895,2,2,2h12c1.105,0,2-0.895,2-2V4c0-1.105-0.895-2-2-2H6C4.895,2,4,2.895,4,4z M6,9 c0-1.105,0.895-2,2-2h0.5C7.672,7,7,6.328,7,5.5S7.672,4,8.5,4S10,4.672,10,5.5S9.328,7,8.5,7H9c1.105,0,2,0.895,2,2v1H6V9z M14,19 H6v-2h8V19z M18,15H6v-2h12V15z M18,6h-5V4h5V6z M18,10h-5V8h5V10z"/>
                      </svg>

                      <span className="text-white font-bold ">
                        {t("CompleteListWorks")}
                      </span>
                  </h3>
              </a>
          </div>
        </div>
      </>
  );
};

export default ResumeCardTwo;

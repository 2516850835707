import React, {useCallback} from 'react';
import PageTitle from "../../Share/PageTitle";
import UseData from "../../Hooks/UseData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect , useState } from "react";
import { FaRegCalendarAlt, FaExternalLinkAlt } from "react-icons/fa";
import { FaFolder, FaCode } from "react-icons/fa";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import Masonry from 'masonry-layout';
import {useTranslation} from "react-i18next";
import Footer from "../../Share/Footer";

const CommonPortfolioItem = () => {
    const navigate = useNavigate();
    const { data } = UseData();
    const { slug } = useParams();
    const [item, setItem] = useState(data.find(e => e.slug === slug));
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if(item === undefined) {
            navigate("/404");
        }
    }, []);

    return (
        <>
            <PageTitle title={item?.title ?? ''} ></PageTitle>

            <section className="bg-white lg:rounded-2xl dark:bg-[#111111]">
                <div data-aos="fade">
                    <div className="container mb-8 px-4 sm:px-5 md:px-10 lg:px-[60px]">
                        <div className="py-12">
                            <h2 className="text-[#ef4060] dark:hover:text-[#FA5252] text-7xl text-center font-bold">
                                {item?.title}
                            </h2>
                            <div className="grid grid-cols-1 my-6">
                                <div className="space-y-2">
                                    <p className="dark:text-white flex items-center text-[15px] sm:text-lg ">
                                        <FaFolder className="sm:text-lg hidden sm:block mr-2 md:text-xl"/>
                                        {t("Category")}:&nbsp;
                                        <span className="font-semibold">{t(item?.tag)}</span>
                                    </p>
                                    <p className="dark:text-white flex items-center text-[15px] sm:text-lg ">
                                        <FaRegCalendarAlt className="sm:text-lg hidden sm:block mr-2 md:text-xl"/>
                                        {t("Year")}:&nbsp;
                                        <span className="font-semibold">{item?.year}</span>
                                    </p>
                                    <p className="dark:text-white flex items-center text-[15px] sm:text-lg ">
                                        <FaCode className="sm:text-lg hidden sm:block mr-2 md:text-xl"/>
                                        {t("Technology")}:&nbsp;
                                        <span className="font-semibold">{item?.technology}</span>
                                    </p>
                                    <p className="dark:text-white flex items-center text-[15px] sm:text-lg ">
                                        <FaExternalLinkAlt className="text-lg mr-2 hidden sm:block"/>
                                        {t("Preview")}:&nbsp;
                                        <span
                                            className="font-semibold transition-all duration-300 ease-in-out hover:text-[#ef4060]">
                                          <a
                                              href={item?.link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                            {item?.linkText}
                                          </a>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <p className="dark:text-white text-2line font-normal text-[15px] sm:text-sm mb-4"
                               dangerouslySetInnerHTML={{__html: item['description_' + i18n.language]}}></p>

                            <LightGallery
                                plugins={[lgZoom]}
                                mode="lg-fade"
                                elementClassNames="msnry -mx-2"
                                getCaptionFromTitleOrAlt={false}
                            >
                                {item.gallery.map((image, index) => {
                                    let imageAvif = require('../../assets/images/portfolio/' + item.path + '/gallery/' + image + '.avif');
                                    let imageJpg = require('../../assets/images/portfolio/' + item.path + '/gallery/' + image + '.jpg');

                                    return (
                                        <a
                                            key={index}
                                            className="gallery-item w-1/2 sm:w-2/6 p-2 flex"
                                            data-src={imageJpg}
                                            data-sources={JSON.stringify([{
                                                srcset: imageAvif,
                                                type: "image/avif"
                                            }])}
                                        >
                                            <picture>
                                                <source srcSet={imageAvif} type="image/avif"/>
                                                <img
                                                    alt={image}
                                                    src={imageJpg}
                                                    className="rounded-xl"
                                                    onLoad={(e) => {
                                                        const masonrySelector = '.msnry'
                                                        new Masonry(masonrySelector)
                                                    }}
                                                />
                                            </picture>
                                        </a>
                                    )
                                })}
                            </LightGallery>
                        </div>
                    </div>

                    <Footer condition={true} bg={"#F8FBFB"} />
                </div>
            </section>
        </>
    );
};

export default CommonPortfolioItem;

import React, {useEffect} from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";
import { useTranslation } from 'react-i18next'

const AboutTwo = () => {
  const { local, serviceArray } = UseData();
  const { t } = useTranslation();

  return (
    <section>
      <PageTitle title={t("About")}></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect lg:block hidden">
              {t("AboutMe")}
            </h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
              <div className=" md:gap-10 mb-12 md:pt-[30px] items-center lg:hidden ">
                <h2 className="after-effect mb-5">About Me</h2>
                <div className="col-span-12 space-y-2.5">
                  <div className="lg:mr-16">
                    <p className="text-gray-lite dark:text-color-910 leading-7">
                      {t("AboutMe1")}
                    </p>
                    <p className="text-gray-lite mt-2.5 dark:text-color-910 leading-7">
                      {t("AboutMe2")}
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      {t("AboutMe3")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite dark:text-color-910 leading-7">
                    {t("AboutMe1")}
                  </p>
                  <p className="text-gray-lite mt-2 dark:text-color-910 leading-7">
                    {t("AboutMe2")}
                  </p>
                  <p className="text-gray-lite leading-7 mt-2 dark:text-color-910">
                    {t("AboutMe3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="pb-12 px-2 sm:px-5 md:px-10 lg:px-14">
            <h3 className="text-5xl dark:text-white font-medium mb-6">
              {t("whatIDo")}
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}
              {serviceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          {/*<div className="px-2 sm:px-5 md:px-10 lg:px-14 ">
            <SliderCommon />
          </div>*/}

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
